APP.header = function () {
	function init() {
		BODY.addClass('sidebar-icon-only')

		$('[data-toggle="minimize"]').on('click', function () {
			if (BODY.hasClass('sidebar-toggle-display') || BODY.hasClass('sidebar-absolute')) {
				BODY.toggleClass('sidebar-hidden')
			} else {
				BODY.toggleClass('sidebar-icon-only')
			}
		})

		$('[data-toggle="offcanvas"]').on('click', function () {
			$('.sidebar-offcanvas').toggleClass('active')
		})

		$(document).on('mouseenter mouseleave', '.sidebar .nav-item', function (ev) {
			var body = $('body')
			var sidebarIconOnly = body.hasClass('sidebar-icon-only')
			var sidebarFixed = body.hasClass('sidebar-fixed')
			if (!('ontouchstart' in document.documentElement)) {
				if (sidebarIconOnly) {
					if (sidebarFixed) {
						if (ev.type === 'mouseenter') {
							body.removeClass('sidebar-icon-only')
						}
					} else {
						var $menuItem = $(this)
						if (ev.type === 'mouseenter') {
							$menuItem.addClass('hover-open')
						} else {
							$menuItem.removeClass('hover-open')
						}
					}
				}
			}
		})

		$('.form-check label,.form-radio label').append('<i class="input-helper"></i>')
	}

	init()
}
