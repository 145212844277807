APP.totos = function () {
	const todoListItem = $('.todo-list')
	const todoListInput = $('.todo-list-input')

	function init() {
		$('.todo-list .todo-item:not(.edit-mode)').append(
			'<div class="edit-icon"><i class="mdi mdi-pencil"></i></div>'
		)

		$('.edit-icon').on('click', function () {
			$(this).parent().addClass('edit-mode')
			$(".todo-list .todo-item button[type='reset']").on('click', function () {
				$(this).closest('.todo-item').addClass('edit-mode')
			})
		})

		$('.todo-list-add-btn').on('click', function (e) {
			e.preventDefault()

			const item = $(this).prevAll('.todo-list-input').val()

			if (item) {
				todoListItem.append(
					"<li><div class='form-check'><label class='form-check-label'><input class='checkbox' type='checkbox'/>" +
						item +
						"<i class='input-helper'></i></label></div><i class='remove mdi mdi-close-circle-outline'></i></li>"
				)
				todoListInput.val('')
			}
		})

		todoListItem.on('change', '.checkbox', function () {
			if ($(this).attr('checked')) {
				$(this).removeAttr('checked')
			} else {
				$(this).attr('checked', 'checked')
			}

			$(this).closest('li').toggleClass('completed')
		})

		todoListItem.on('click', '.remove', function () {
			$(this).parent().remove()
		})
	}

	init()
}
