APP.init = function () {
	APP.device()
	APP.smooth()
	APP.general()
	APP.header()
	APP.chart()
	APP.totos()
	APP.auth()
	APP.select2()
	APP.addTagging()
}
