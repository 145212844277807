APP.addTagging = function () {
	let countCardTagging = 0
	const cardGroup = $('#add-card-group')
	const btnAddTagging = $('#btn-add-tagging')
	const arrGroupTagging = []

	const [getCampaign, setCampaign] = useCampaign()
	const [getPage, setPage] = usePage()
	const [getPageTemplate, setPageTemplate] = usePageTemplate()
	const [getPosition, setPosition] = usePosition()
	const [getComponent, setComponent] = useComponent()
	const [getComponentPage, setComponentPage] = useComponentPage()
	const [getComponentField, setComponentField] = useComponentField()

	async function init() {
		// const dataCampaigns = await getData('/data/campaigns.json')
		// const dataPages = await getData('/data/pages.json')
		// const dataPageTemplates = await getData('/data/page-templates.json')
		// const dataComponents = await getData('/data/components.json')

		// const dataCampaigns = await getData(`${apiUrl}/api/campaign`)
		// const dataPages = await getData(`${apiUrl}/api/page`)
		// const dataPageTemplates = await getData(`${apiUrl}/api/page-template`)
		// const dataComponents = await getData(`${apiUrl}/api/component`)

		if (cardGroup.length === 0) return

		APP.showLoading()

		Promise.all([
			fetch(`${apiUrl}/api/campaign`).then((res) => res.json()),
			fetch(`${apiUrl}/api/page`).then((res) => res.json()),
			fetch(`${apiUrl}/api/page-template`).then((res) => res.json()),
			fetch(`${apiUrl}/api/component`).then((res) => res.json()),
		])
			.then(([dataCampaigns, dataPages, dataPageTemplates, dataComponents]) => {
				APP.hideLoading()
				setCampaign(dataCampaigns.data)
				setPage(dataPages.data)
				setComponent(dataComponents.data)
				setPageTemplate(dataPageTemplates.data)
			})
			.catch((error) => console.log(error))

		// setCampaign(dataCampaigns)
		// setPage(dataPages)
		// setComponent(dataComponents)
		// setPageTemplate(dataPageTemplates)

		// console.log('getCampaign: ', getCampaign())
		// console.log('getPage: ', getPage())
		// console.log('getPageTemplate: ', getPageTemplate())

		APP.validate('.form-add-tagging')
		btnAddTagging.on('click', handleAddTagging)
	}

	// async function getData(url) {
	// 	const data = await $.getJSON(url)
	// 	return data
	// }

	async function getData(url) {
		const response = await fetch(url, {
			headers: {
				'Content-Type': 'application/json',
			},
		})

		const res = await response.json()

		return res.data
	}

	function useCampaign() {
		let _campaign = null

		function getCampaign() {
			return _campaign
		}

		function setCampaign(data) {
			_campaign = [...data]
		}

		return [getCampaign, setCampaign]
	}

	function usePage() {
		let _page = null

		function getPage() {
			return _page
		}

		function setPage(data) {
			_page = [...data]
		}

		return [getPage, setPage]
	}

	function usePageTemplate() {
		let _pageTemplate = null

		function getPageTemplate() {
			return _pageTemplate
		}

		function setPageTemplate(data) {
			_pageTemplate = [...data]
		}

		return [getPageTemplate, setPageTemplate]
	}

	function usePosition() {
		let _position = null

		function getPosition() {
			return _position
		}

		function setPosition(data) {
			_position = [...data]
		}

		return [getPosition, setPosition]
	}

	function useComponent() {
		let _component = null

		function getComponent() {
			return _component
		}

		function setComponent(data) {
			_component = [...data]
		}

		return [getComponent, setComponent]
	}

	function useComponentPage() {
		let _componentPage = null

		function getComponentPage() {
			return _componentPage
		}

		function setComponentPage(data) {
			_componentPage = [...data]
		}

		return [getComponentPage, setComponentPage]
	}

	function useComponentField() {
		let _componentInput = null

		function getComponentField() {
			return _componentInput
		}

		function setComponentField(data) {
			_componentInput = [...data]
		}

		return [getComponentField, setComponentField]
	}

	function handleAddTagging() {
		const cardItem = cardGroup.find('.card:last-child')
		let index = cardItem.data('index')

		if (index === undefined) index = -1

		index++
		countCardTagging++

		renderTaggingGroupToDom(index)
		$('.card-save-tagging').removeClass('d-none')
	}

	function checkTaggingDuplicate(
		valueCampaign,
		valuePage,
		valuePosition,
		valueComponent,
		groupIndex
	) {
		const _this = $(`[name="check_tagging_duplicate_${groupIndex}"]`)
		const parent = _this.parents('.form-group')
		const error = parent.find('.form-error')
		const errorText = error.data('error-duplicate')
		const valueMerge = parseInt(valueCampaign + valuePage + valuePosition + valueComponent)
		let arrGroupTaggingEqual = []

		if (valueCampaign && valuePage && valuePosition && valueComponent) {
			arrGroupTagging[groupIndex] = valueMerge

			if (arrGroupTagging.length === 1) {
				_this.val(valueMerge)
				parent.removeClass(CLASS._invalid).removeClass(CLASS._error).addClass(CLASS._valid)
			}

			if (arrGroupTagging.length > 1 && arrGroupTagging[groupIndex] === valueMerge) {
				arrGroupTaggingEqual = [...arrGroupTagging]
				arrGroupTaggingEqual.splice(groupIndex, 1)

				if (arrGroupTaggingEqual.includes(valueMerge)) {
					setTimeout(() => {
						parent.removeClass(CLASS._invalid).removeClass(CLASS._valid).addClass(CLASS._error)
						error.html(errorText)
					}, 150)
					_this.val('')
					arrGroupTagging.splice(groupIndex, 1)
				} else {
					_this.val(valueMerge)
					parent.removeClass(CLASS._invalid).addClass(CLASS._valid).removeClass(CLASS._error)
					error.html('')
				}
			}
		}

		APP.validate('.form-add-tagging')
	}

	function renderInputsItem(name, groupIndex) {
		return `<div class="${name}_${groupIndex}">
			<ul class="nav nav-tabs mb-3" id="tab-carousel-${groupIndex}" role="tablist"></ul>
			<div class="tab-content" id="tab-carousel-content-${groupIndex}"></div>
		 </div>`
	}

	function renderTabItem(data, tabIndex, groupIndex) {
		const { name, title } = data

		return `<li class="nav-item" role="presentation">
			<button class="nav-link ${
				tabIndex === 0 ? 'active' : ''
			}" id="${name}-tab-${groupIndex}" data-bs-toggle="tab" data-bs-target="#${name}-${groupIndex}" type="button" role="tab" aria-controls="${name}-${groupIndex}" aria-selected="${
			tabIndex === 0 ? 'true' : 'false'
		}">${title}</button>
		</li>`
	}

	function renderTabContentItem(data, tabIndex, groupIndex) {
		const { name, fields } = data
		const dataOneFields = fields.filter(
			(data) => data.isDisable === undefined && data.type === 'checkbox'
		)
		const dataOneGroupFields = fields.filter(
			(data) => data.isDisable === undefined && data.type === 'field_group'
		)

		return `<div class="tab-pane fade show ${
			tabIndex === 0 ? 'active' : ''
		}" id="${name}-${groupIndex}" role="tabpanel-${groupIndex}" aria-labelledby="${name}-tab-${groupIndex}">
			${dataOneFields
				.map((dataOneField) => {
					return renderCheckbox(dataOneField, name, groupIndex, tabIndex)
				})
				.join('')}
			<div class="accordion accordion-${tabIndex} mb-3" id="accordion-flush-${name}">
				${dataOneGroupFields
					.map((dataOneGroupField, indexOne) => {
						return `<div class="accordion-item">
								<h2 class="accordion-header" id="heading-${name}-${
							dataOneGroupField.name
						}-${groupIndex}-${tabIndex}">
									<button class="accordion-button ${
										indexOne !== 0 ? 'collapsed' : ''
									}" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-${name}-${
							dataOneGroupField.name
						}-${groupIndex}-${tabIndex}" aria-expanded="${
							indexOne === 0 ? 'true' : 'false'
						}" aria-controls="collapse-${name}-${dataOneGroupField.name}-${groupIndex}-${tabIndex}">
										${dataOneGroupField.title}
									</button>
								</h2>
								<div id="collapse-${name}-${
							dataOneGroupField.name
						}-${groupIndex}-${tabIndex}" class="accordion-collapse collapse ${
							indexOne === 0 ? 'show' : ''
						}" aria-labelledby="heading-${name}-${
							dataOneGroupField.name
						}-${groupIndex}-${tabIndex}" data-bs-parent="#accordion-flush-${name}">
									<div class="accordion-body">
										${renderGroupFieldTwo(dataOneGroupField.fields, name, dataOneGroupField.name, groupIndex, tabIndex)}
									</div>
								</div>
							</div>`
					})
					.join('')}
			</div>
		</div>`
	}

	function renderGroupFieldTwo(data, tabName, accordionName, groupIndex, tabIndex) {
		const dataTwoFields = data.filter((item) => item.isDisable === undefined)

		return dataTwoFields
			.map((fieldTwo, indexTwo) => {
				if (fieldTwo.isDisable === undefined) {
					switch (fieldTwo.type) {
						case 'text':
							return renderInput(
								fieldTwo,
								tabName,
								accordionName,
								'',
								groupIndex,
								tabIndex,
								indexTwo
							)
						case 'select':
							return renderSelect(
								fieldTwo,
								tabName,
								accordionName,
								'',
								groupIndex,
								tabIndex,
								indexTwo
							)
						case 'field_group':
							return `<div class="form-group">
						<label class="form-label mb-3">${fieldTwo.title}</label> ${renderFieldGroup(
								fieldTwo.fields,
								tabName,
								accordionName,
								fieldTwo.name,
								groupIndex,
								tabIndex,
								indexTwo
							)}</div>`
						case 'textarea':
							return renderTextarea(
								fieldTwo,
								tabName,
								accordionName,
								'',
								groupIndex,
								tabIndex,
								indexTwo
							)
						case 'date':
							return renderDate(
								fieldTwo,
								tabName,
								accordionName,
								'',
								groupIndex,
								tabIndex,
								indexTwo
							)
						default:
							break
					}
				}
			})
			.join('')
	}

	function renderInput(item, tabName, accordionName, fieldTwoName, groupIndex, ...nums) {
		let groupNumber = nums.join('')

		return `<div class="form-group ${item?.required ? 'is-invalid' : ''}" ${
			item?.required ? 'data-required' : ''
		}>
			<label class="form-label" data-image-tooltip="${
				item?.visual_image ? item?.visual_image : ''
			}" for="${item?.name}_${groupIndex}${groupNumber}">${
			item?.title
		} <span class="add-tooltip"></span></label>
			<input class="form-control" type="${
				item?.type
			}" name="items[group_${groupIndex}][component_data][${tabName}][${accordionName}]${
			fieldTwoName && `[${fieldTwoName}]`
		}[${item?.name}]" id="${item?.name}_${groupIndex}${groupNumber}">
			<span class="form-error" data-error-wrong="Please enter your ${item?.title}"></span>
		</div>`
	}

	function renderCheckbox(item, tabName, groupIndex, ...nums) {
		const groupNumber = nums.join('')

		return `<div class="form-group ${item.required ? 'is-invalid' : ''}" ${
			item.required ? 'data-required' : ''
		}>
			<label class="form-label" for="${item.name}_${groupIndex}${groupNumber}">${item.title}</label>
			<input class="checkbox" type="${
				item.type
			}" name="items[group_${groupIndex}][component_data][${tabName}][${item.name}]" id="${
			item.name
		}_${groupIndex}${groupNumber}" ${
			item.default ? 'checked' : ''
		} data-group-index="${groupIndex}" data-tab-name="${tabName}" data-tab-index="${groupNumber}" value="${
			item.name
		}">
			<span class="form-error" data-error-wrong="Please enter your ${item.title}"></span>
		</div>`
	}

	function renderSelect(item, tabName, accordionName, fieldTwoName, groupIndex, ...nums) {
		const groupNumber = nums.join('')

		return `<div class="form-group">
		<label class="form-label" data-image-tooltip="${
			item?.visual_image ? item?.visual_image : ''
		}" for="${item?.name}_${groupIndex}${groupNumber}">${
			item?.title
		} <span class="add-tooltip"></span></label>
			<div class="select">
				<select class="form-control" id="${
					item.name
				}_${groupIndex}${groupNumber}" name="items[group_${groupIndex}][component_data][${tabName}][${accordionName}]${
			fieldTwoName && `[${fieldTwoName}]`
		}[${
			item.name
		}]" data-search="false" data-input-placeholder="Enter your keyword" style="width: 100%">
						${item.option
							.map((option) => {
								return `<option value="${option.name}">${option.title}</option>`
							})
							.join('')}
					</select>
			</div>
		</div>`
	}

	function renderFieldGroup(
		data,
		tabName,
		accordionName,
		fieldTwoName,
		groupIndex,
		tabIndex,
		indexTwo
	) {
		const dataThreeFields = data.filter((item) => item.enable === undefined)

		return dataThreeFields
			.map((fieldThree, indexThree) => {
				if (fieldThree.isDisable === undefined) {
					switch (fieldThree.type) {
						case 'text':
							return renderInput(
								fieldThree,
								tabName,
								accordionName,
								fieldTwoName,
								groupIndex,
								tabIndex,
								indexTwo,
								indexThree
							)
						case 'select':
							return renderSelectGroupField(
								fieldThree,
								tabName,
								accordionName,
								fieldTwoName,
								groupIndex,
								tabIndex,
								indexTwo,
								indexThree
							)
						default:
							break
					}
				}
			})
			.join('')
	}

	function renderSelectGroupField(item, tabName, accordionName, fieldTwoName, groupIndex, ...nums) {
		const groupNumber = nums.join('')
		const options = item.option

		return `<div class="form-group row">
			<label class="form-label col-2 mb-0 ps-3" data-image-tooltip="${
				item?.visual_image ? item?.visual_image : ''
			}" for="${item.name}_${groupIndex}${groupNumber}">${
			item.title
		} <span class="add-tooltip"></span></label>
			<div class="select col-10">
				<select class="form-control" id="${
					item.name
				}_${groupIndex}${groupNumber}" name="items[group_${groupIndex}][component_data][${tabName}][${accordionName}]${
			fieldTwoName && `[${fieldTwoName}]`
		}[${
			item.name
		}]" data-search="false" data-input-placeholder="Enter your keyword" style="width: 100%">
					${options
						.map((option) => {
							return `<option value="${option.name}" ${
								item.default === parseInt(option.name) ? 'selected' : ''
							}>${option.title}</option>`
						})
						.join('')}
				</select>
			</div>
		</div>`
	}

	function renderGroupFields(fields, tabName, groupIndex, tabIndex) {
		const dataOneGroupFields = fields.filter(
			(data) => data.isDisable === undefined && data.type === 'field_group'
		)

		return dataOneGroupFields
			.map((dataOneGroupField, indexOne) => {
				return `<div class="accordion-item">
						<h2 class="accordion-header" id="heading-${tabName}-${
					dataOneGroupField.name
				}-${groupIndex}-${tabIndex}">
							<button class="accordion-button ${
								indexOne !== 0 ? 'collapsed' : ''
							}" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-${tabName}-${
					dataOneGroupField.name
				}-${groupIndex}-${tabIndex}" aria-expanded="${
					indexOne === 0 ? 'true' : 'false'
				}" aria-controls="collapse-${tabName}-${dataOneGroupField.name}-${groupIndex}-${tabIndex}">
								${dataOneGroupField.title}
							</button>
						</h2>
						<div id="collapse-${tabName}-${
					dataOneGroupField.name
				}-${groupIndex}-${tabIndex}" class="accordion-collapse collapse ${
					indexOne === 0 ? 'show' : ''
				}" aria-labelledby="heading-${tabName}-${
					dataOneGroupField.name
				}-${groupIndex}-${tabIndex}" data-bs-parent="#accordion-flush-${tabName}">
							<div class="accordion-body">
								${renderGroupFieldTwo(
									dataOneGroupField.fields,
									tabName,
									dataOneGroupField.name,
									groupIndex,
									tabIndex,
									indexOne
								)}
							</div>
						</div>
					</div>`
			})
			.join('')
	}

	function renderCampaignSelect(options, groupIndex) {
		return `<div class="form-group is-invalid" data-required>
			<label class="form-label" for="campaigns_${groupIndex}">Campaigns</label>
			<div class="select">
				<select class="form-control" id="campaigns_${groupIndex}" name="items[group_${groupIndex}][campaign_id]" data-search="true" data-input-placeholder="Enter your keyword" style="width: 100%">
					<option value="">Choose Campaign</option>
					${options
						.map((option) => {
							return `<option value="${option.id}">${option.label}</option>`
						})
						.join('')}
				</select>
			</div>
			<span class="form-error" data-error-wrong="Please choose campaign"></span>
		</div>`
	}

	function renderPageSelect(options, groupIndex) {
		return `<div class="form-group is-invalid" data-required>
			<label class="form-label" for="pages_${groupIndex}">Pages</label>
			<div class="select">
				<select class="form-control" id="pages_${groupIndex}" name="items[group_${groupIndex}][page_id]" data-search="true" data-input-placeholder="Enter your keyword" style="width: 100%">
					<option value="">Choose Page</option>
					${options
						.map((option) => {
							return `<option value="${option.id}">${option.label}</option>`
						})
						.join('')}
				</select>
			</div>
			<span class="form-error" data-error-wrong="Please choose page"></span>
		</div>`
	}

	function renderOptionPosition(options, optionName) {
		return `<option value="">${optionName}</option>
		${options
			.map((option) => {
				return `<option value="${option.id}" data-image-tooltip="${option.visual_image}">${option.label}</option>`
			})
			.join('')}`
	}

	function renderOptionComponent(options, optionName) {
		return `<option value="">${optionName}</option>
		${options
			.map((option) => {
				return `<option value="${option.id}">${option.code}</option>`
			})
			.join('')}`
	}

	function renderTaggingGroupItem(groupIndex) {
		return `<div class="card mb-3" data-index=${groupIndex}>
			<div class="card-body">
				<div class="form-group is-invalid" data-required>
					<input class="form-control" type="hidden" name="check_tagging_duplicate_${groupIndex}" />
					<span class="form-error" data-error-duplicate="Tagging is duplicate please choose again"></span>
				</div>
				${renderCampaignSelect(getCampaign(), groupIndex)}
				<div class="card-page-${groupIndex} d-none">
					${renderPageSelect(getPage(), groupIndex)}
					<div class="form-group is-invalid" data-required>
						<label class="form-label" for="positions_${groupIndex}">Positions <span class="add-tooltip"></span></label>
						<div class="select">
							<select class="form-control" id="positions_${groupIndex}" name="items[group_${groupIndex}][position_id]" data-search="true" data-input-placeholder="Enter your keyword" style="width: 100%" disabled>
								<option value="">Choose Position</option>
							</select>
						</div>
						<span class="form-error" data-error-wrong="Please choose position"></span>
					</div>
					<div class="form-group is-invalid" data-required>
						<label class="form-label" for="components_${groupIndex}">Components</label>
						<div class="select">
							<select  class="form-control" id="components_${groupIndex}" name="items[group_${groupIndex}][component_id]" data-search="true" data-input-placeholder="Enter your keyword" style="width: 100%" disabled>
								<option value="">Choose Component</option>
							</select>
						</div>
						<span class="form-error" data-error-wrong="Please choose component"></span>
					</div>
				</div>
				<div id="inputs-${groupIndex}"></div>
			</div>
			<div class="card-footer text-right">
				<button class="btn btn-gradient-wraning btn-delete-group">Delete</button>
			</div>
		</div>`
	}

	function renderTabsToDom(data, groupIndex) {
		const tabCarouselGroupIndex = $(`#tab-carousel-${groupIndex}`)
		const tabs = data.filter((item) => item.isDisable === undefined)
		const items = tabs.map((item, tabIndex) => renderTabItem(item, tabIndex, groupIndex)).join('')

		tabCarouselGroupIndex.html(items)
	}

	function renderTabContentToDom(fields, componentId, groupIndex) {
		const tabCarouselContentGroupIndex = $(`#tab-carousel-content-${groupIndex}`)
		const dataFieldInputs = fields.filter((data) => data.isDisable === undefined)

		const items = dataFieldInputs
			.map((item, tabIndex) => renderTabContentItem(item, tabIndex, groupIndex))
			.join('')

		tabCarouselContentGroupIndex.html(items)

		if (componentId === 1) {
			$('[id*="use_carousel"]').each(function () {
				const obj = $(this)
				const parent = obj.parent()
				const accordion = parent.next()
				const groupIndexCurrent = obj.data('group-index')
				const tabName = obj.data('tab-name')
				const tabIndex = obj.data('tab-index')

				if (groupIndexCurrent === groupIndex) {
					html = `${renderGroupFields(
						dataFieldInputs[groupIndex].fields,
						tabName,
						groupIndexCurrent,
						tabIndex
					)}`

					if (obj.is(':checked')) {
						accordion.html(html)
						addTooltip()
					} else {
						accordion.html('')
					}
					APP.select2()
					APP.validate('.form-add-tagging')
				}
			})

			$('[id*="use_carousel"]').on('change', function () {
				const obj = $(this)
				const parent = obj.parent()
				const accordion = parent.next()
				const groupIndexCurrent = obj.data('group-index')
				const tabName = obj.data('tab-name')
				const tabIndex = obj.data('tab-index')

				if (groupIndexCurrent === groupIndex) {
					html = `${renderGroupFields(
						dataFieldInputs[groupIndex].fields,
						tabName,
						groupIndexCurrent,
						tabIndex
					)}`

					if (obj.is(':checked')) {
						accordion.html(html)
						addTooltip()
					} else {
						accordion.html('')
					}
					APP.select2()
					APP.validate('.form-add-tagging')
				}
			})
		}

		addTooltip()

		$('[data-bs-toggle="tooltip"]').tooltip()
		APP.select2()
		APP.validate('.form-add-tagging')
	}

	function addTooltip() {
		$('.form-label[data-image-tooltip]').each(function () {
			const _this = $(this)
			const imageTooltip = _this.data('image-tooltip') ? _this.data('image-tooltip') : ''
			const addTooltip = _this.find('.add-tooltip')

			if (imageTooltip) {
				addTooltip.html(
					`<span data-bs-toggle="tooltip" data-bs-placement="right" data-bs-html="true" data-bs-custom-class="custom-tooltip" title="<image src='${imageTooltip}' />"><i class="mdi mdi-information-outline"></i></span>`
				)
				$('[data-bs-toggle="tooltip"]').tooltip()
			} else {
				addTooltip.html('')
			}
		})
	}

	function renderTaggingGroupToDom(groupIndex) {
		const items = renderTaggingGroupItem(groupIndex)
		cardGroup.append(items)
		APP.select2()
		APP.validate('.form-add-tagging')

		const cardSaveTagging = $('.card-save-tagging')
		const btnDeleteGroup = $('.btn-delete-group')
		const selectGroup = $('select')
		const campainsGroupIndex = $(`#campaigns_${groupIndex}`)
		const pagesGroupIndex = $(`#pages_${groupIndex}`)
		const postionsGroupIndex = $(`#positions_${groupIndex}`)
		const componentsGroupIndex = $(`#components_${groupIndex}`)
		const inputsGroupIndex = $(`#inputs-${groupIndex}`)
		const cardPageGroupIndex = $(`.card-page-${groupIndex}`)

		btnDeleteGroup.off('click').on('click', function (evt) {
			evt.preventDefault()
			const _this = $(this)
			const parent = _this.parents('.card')
			const index = parent.index()

			countCardTagging--
			if (countCardTagging <= 0) {
				countCardTagging = 0
				cardSaveTagging.addClass('d-none')
			}

			arrGroupTagging.splice(index, 1)
			parent.remove()
		})

		campainsGroupIndex.on('change', function () {
			const _this = $(this)
			const value = _this.val()

			if (value) {
				cardPageGroupIndex.removeClass('d-none')
			} else {
				cardPageGroupIndex.addClass('d-none')
				inputsGroupIndex.html('')
				pagesGroupIndex.val('')
				postionsGroupIndex.val('')
				componentsGroupIndex.val('')
				selectGroup.trigger('change.select2')
			}
		})

		pagesGroupIndex.on('change', function () {
			const _this = $(this)
			const valuePage = _this.val() ? parseInt(_this.val()) : ''
			const card = _this.parents('.card')
			const pageIndex = card.data('index')
			const pageFilter = getPage().find((item) => item.id === valuePage)

			if (valuePage) {
				postionsGroupIndex.prop('disabled', false)
				componentsGroupIndex.prop('disabled', false)
			} else {
				postionsGroupIndex.val('').prop('disabled', true)
				componentsGroupIndex.val('').prop('disabled', true)
			}

			if (pageFilter) {
				setPosition(pageFilter.positions.data)
				const positionOptionItems = renderOptionPosition(getPosition(), 'Choose Position')
				const positionSelect = $(`#positions_${pageIndex}`)
				const pageTemplateFilter = getPageTemplate().find(
					(item) => item.id === pageFilter.page_template_id
				)

				positionSelect.html(positionOptionItems)

				if (pageTemplateFilter) {
					setComponentPage(pageTemplateFilter.components.data)
					const componentOptionItems = renderOptionComponent(getComponentPage(), 'Choose Component')
					const componentSelect = $(`#components_${pageIndex}`)
					componentSelect.html(componentOptionItems)
				}
			}

			inputsGroupIndex.html('')
			selectGroup.trigger('change.select2')
		})

		postionsGroupIndex.on('change', function () {
			const _this = $(this)
			const formGroup = _this.parents('.form-group')
			const formLabel = formGroup.find('.form-label')
			const addTooltip = formLabel.find('.add-tooltip')
			const imageTooltip = _this.find('option:selected').data('image-tooltip')
			const valuePosition = _this.val() ? parseInt(_this.val()) : ''
			const valueCampaign = campainsGroupIndex.val() ? parseInt(campainsGroupIndex.val()) : ''
			const valueComponent = componentsGroupIndex.val() ? parseInt(componentsGroupIndex.val()) : ''
			const valuePage = pagesGroupIndex.val() ? parseInt(pagesGroupIndex.val()) : ''

			if (imageTooltip) {
				addTooltip.html(
					`<span data-bs-toggle="tooltip" data-bs-placement="right" data-bs-html="true" data-bs-custom-class="custom-tooltip" title="<image src='${imageTooltip}' />"><i class="mdi mdi-information-outline"></i></span>`
				)
				// new bootstrap.Tooltip('[data-bs-toggle="tooltip"]', {
				// 	boundary: document.body,
				// })
				$('[data-bs-toggle="tooltip"]').tooltip()
			} else {
				addTooltip.html('')
			}
			checkTaggingDuplicate(valueCampaign, valuePage, valuePosition, valueComponent, groupIndex)
		})

		componentsGroupIndex.on('change', function () {
			const _this = $(this)
			const valueComponent = _this.val() ? parseInt(_this.val()) : ''
			const valueComponentText = _this.find('option:selected').text()
			const componentFilter = getComponent().find((item) => item.id === valueComponent)
			const dataComponentInputs =
				componentFilter && componentFilter.value !== 'updating...'
					? JSON.parse(componentFilter.value)
					: []
			const valueCampaign = campainsGroupIndex.val() ? parseInt(campainsGroupIndex.val()) : ''
			const valuePage = pagesGroupIndex.val() ? parseInt(pagesGroupIndex.val()) : ''
			const valuePosition = postionsGroupIndex.val() ? parseInt(postionsGroupIndex.val()) : ''
			const inputItems = renderInputsItem(valueComponentText, groupIndex)

			checkTaggingDuplicate(valueCampaign, valuePage, valuePosition, valueComponent, groupIndex)

			if (dataComponentInputs.length > 0) {
				setComponentField(dataComponentInputs)
				//console.log('getComponentField: ', getComponentField())
				inputsGroupIndex.html(inputItems)
				renderTabsToDom(getComponentField(), groupIndex)
				renderTabContentToDom(getComponentField(), valueComponent, groupIndex)
			} else {
				inputsGroupIndex.html('')
			}

			APP.select2()
			APP.validate('.form-add-tagging')
		})
	}

	init()
}
