APP.select2 = function () {
	function init() {
		$('select').each(function () {
			const _this = $(this)
			const isSearch = _this.data('search') ? Boolean(_this.data('search')) : false
			const inputPlaceholder = _this.data('input-placeholder')
			const apiUrl = _this.data('api-url') ? _this.data('api-url') : ''
			const limit = _this.data('limit') ? _this.data('limit') : -1
			const typeValue = _this.data('result-attr-name') ? _this.data('result-attr-name') : ''
			let callback = _this.data('callback') ? _this.data('callback') : ''
			let func = callback ? new Function('repo', callback) : ''
			let url = ''

			if (apiUrl === '') {
				if (!_this.hasClass('select2-hidden-accessible')) {
					_this.select2({
						with: 'resolve',
						minimumResultsForSearch: isSearch ? 0 : -1,
						searchInputPlaceholder: isSearch ? inputPlaceholder : '',
						templateSelection: function (data, container) {
							if (data.id !== '') {
								$(container).addClass('select2-selection__rendered--value')
							} else {
								$(container).removeClass('select2-selection__rendered--value')
							}
							return data.text
						},
					})
				}
			} else {
				if (limit >= 0) {
					url = `${apiUrl}?limit=${limit}&page=1`
				} else {
					url = apiUrl
				}

				if (!_this.hasClass('select2-hidden-accessible')) {
					_this.select2({
						ajax: {
							url: url,
							dataType: 'json',
							delay: 250,
							data: function (params) {
								return {
									q: params.term,
									page: params.page,
									limit: params.limit,
								}
							},
							processResults: function (response, params) {
								let results = []
								params.page = params.page || 1
								params.limit = params.limit || limit

								if (typeValue === 'label') {
									response.data.map((item) => results.push({ ...item, id: item.label }))
								} else if (typeValue === 'code') {
									response.data.map((item) => results.push({ ...item, id: item.code }))
								} else {
									results = response.data
								}

								console.log(results)

								if (params.limit >= 0) {
									return {
										results: results,
										pagination: {
											more: params.page * params.limit < response.meta.total,
										},
									}
								} else {
									return {
										results: results,
									}
								}
							},
							cache: true,
						},
						allowClear: true,
						with: 'resolve',
						//placeholder: 'Search for a repository',
						//minimumInputLength: 1,
						minimumResultsForSearch: isSearch ? 0 : -1,
						searchInputPlaceholder: isSearch ? inputPlaceholder : '',
						templateSelection: function (data, container) {
							if (data.id !== '') {
								$(container).addClass('select2-selection__rendered--value')
							} else {
								$(container).removeClass('select2-selection__rendered--value')
							}
							return data.label ? data.label : data.code ? data.code : data.text
						},
						templateResult: function (repo) {
							if (repo.loading) return repo.text

							let $container = ''

							if (callback) {
								$container = func(repo)
							} else {
								$container = $(
									`<div class='select2-result-repository clearfix'>
										<div class='select2-result-repository__meta'>
											<div class='select2-result-repository__title'></div>
										</div>
									</div>`
								)

								$container
									.find('.select2-result-repository__title')
									.text(repo.label ? repo.label : repo.code ? repo.code : repo.text)
							}

							return $container
						},
					})
				}
			}
		})
	}

	init()
}
