APP.general = function () {
	function init() {
		const tooltipTriggerList = [].slice.call(
			document.querySelectorAll('[data-bs-toggle="tooltip"]')
		)
		tooltipTriggerList.map(function (tooltipTriggerEl) {
			return new bootstrap.Tooltip(tooltipTriggerEl)
		})

		$('[data-cta_type=delete]').on('click', function (e) {
			e.preventDefault()
			const url = $(this).attr('href')
			const result = confirm('Are you sure to delete this record!')

			if (result) {
				APP.showLoading()

				$.ajax({
					method: 'delete',
					url: url,
					data: {
						_token: crsf_token,
					},
				})
					.done(() => {
						APP.hideLoading()
						window.location.reload()
					})
					.fail((errors) => {
						APP.hideLoading()
						console.log(errors)
						alert('Something is going wrong!')
					})
			}
		})
	}

	init()
}
