APP.auth = function () {
	function init() {
		APP.validate('.form-signin')
		APP.validate('.form-signup')
		APP.validate('.form-add')
		APP.validate('.form-forgot')
		APP.validate('.form-change')
	}

	init()
}
