APP.validate = function (element, options) {
	const el = $(element)
	const btnSubmit = el.find('[type="submit"]')
	const fields = el.find('.form-group')
	const requiredFields = el.find('[data-required]')

	const settings = $.extend(
		{
			isAjax: false,
			onComplete: function () {},
		},
		options
	)

	function init() {
		checkSubmit()
		checkKeyCode()
		btnSubmit.off('click').on('click', handleSubmit)
	}

	function handleSubmit(e) {
		e.preventDefault()

		let isFlag = true

		if (fields.length > 0) {
			fields.each(function () {
				const field = $(this)
				const input = field.find('input, textarea, select')
				const type = input.attr('type')
				const error = field.find('.form-error')
				const messageEmail = error.data('error-email')

				let value = input.val()

				field.addClass(CLASS._valid)

				if (value !== '') {
					switch (type) {
						case 'email':
							if (!checkEmail(value)) {
								showError(error, field, messageEmail)
							}
							break
						default:
							break
					}
				}

				input.on('keyup', function (evt) {
					const obj = $(this)
					const type = obj.attr('type')
					const name = obj.attr('name')
					let value = obj.val()

					if (obj.attr('name') !== 'phone') {
						if (value !== '' && type !== 'radio' && type !== 'checkbox') {
							removeError(error, field)
						} else {
							if (obj.is(':checked')) {
								removeError(error, field)
							}
						}
					}
				})
			})

			fields.each(function () {
				const field = $(this)
				const input = field.find('input, textarea, select')
				const type = input.attr('type')
				const value = input.val()

				if (value !== '') {
					switch (type) {
						case 'email':
							if (!checkEmail(value)) {
								inputFocus(input, field)
								return false
							}
							break
						default:
							break
					}
				}
			})
		}

		if (requiredFields.length > 0) {
			requiredFields.each(function () {
				const field = $(this)
				const input = field.find('input, textarea, select')
				const type = input.attr('type')
				const name = input.attr('name')
				const error = field.find('.form-error')
				const messageWrong = error.data('error-wrong')
				const messageEmail = error.data('error-email')
				const messagePhone = error.data('error-phone')
				const messageFileExtension = error.data('error-file-extension')
				const messageFileSize = error.data('error-file-size')
				let captchaResponse = ''
				let value = input.val()

				removeError(error, field)
				field.removeClass(CLASS._invalid).removeClass(CLASS._error).addClass(CLASS._valid)

				if (typeof grecaptcha === undefined) captchaResponse = grecaptcha.getResponse()
				if (typeof grecaptcha === undefined && captchaResponse.length <= 0) {
					showError(error, field, messageWrong)
					isFlag = false
				}

				if (value === '') {
					showError(error, field, messageWrong)
					isFlag = false
				} else {
					switch (type) {
						case 'email':
							if (!checkEmail(value)) {
								showError(error, field, messageEmail)
							}
							break
						case 'number':
							if (!checkPhone(value)) {
								showError(error, field, messagePhone)
							}
							break
						case 'radio':
							if (!input.is(':checked')) {
								showError(error, field, messageWrong)
							}
							break
						case 'checkbox':
							if (!input.is(':checked')) {
								showError(error, field, messageWrong)
							}
							break
						case 'password':
							const valuePassword = $('[name="password"]').val()
							const confirmPassword = $('[name="confirmation-password"]')
							const field = confirmPassword.parent()
							const valueConfirmPassword = confirmPassword.val()
							const error = confirmPassword.next()
							const messageConfirmPassword = error.data('error-confirm-password')

							if (name === 'confirmation-password') {
								if (value !== valuePassword) {
									showError(error, field, messageConfirmPassword)
								} else {
									removeError(error, field)
								}
							}

							if (valueConfirmPassword !== '' && name === 'password') {
								if (value !== valueConfirmPassword) {
									showError(error, field, messageConfirmPassword)
								} else {
									removeError(error, field)
								}
							}
							break
						case 'file':
							if (!hasExtension(value, ['.doc', '.docx', '.pdf', '.png', '.jpg', '.jpeg'])) {
								showError(error, field, messageFileExtension)
								return false
							} else if (!validateSize(input)) {
								showError(error, field, messageFileSize)
								return false
							}
							break
						default:
							break
					}
				}
			})

			requiredFields.each(function () {
				const field = $(this)
				const input = field.find('input, textarea, select')
				const type = input.attr('type')
				const value = input.val()

				if (value === '') {
					inputFocus(input, field)
					return false
				} else {
					switch (type) {
						case 'email':
							if (!checkEmail(value)) {
								inputFocus(input, field)
								return false
							}
							break
						case 'number':
							if (!checkPhone(value)) {
								inputFocus(input, field)
								return false
							}
							break
						case 'radio':
							if (!input.is(':checked')) {
								inputFocus(input, field)
								return false
							}
							break
						case 'checkbox':
							if (!input.is(':checked')) {
								inputFocus(input, field)
								return false
							}
							break
						case 'file':
							if (!hasExtension(value, ['.doc', '.docx', '.pdf', '.png', '.jpg', '.jpeg'])) {
								inputFocus(input, field)
								return false
							} else if (!validateSize(input)) {
								inputFocus(input, field)
								return false
							}
							break
						default:
							break
					}
				}
			})

			console.log(isFlag, !fields.hasClass(CLASS._error), fields.hasClass(CLASS._valid))

			if (!fields.hasClass(CLASS._error) && fields.hasClass(CLASS._valid)) {
				let data = objectifyForm(el.serializeArray())
				settings.onComplete(data)
				if (isFlag && !settings.isAjax) {
					setTimeout(() => {
						el.submit()
					}, 1)
				}
			}
		} else {
			el.submit()
		}
	}

	function checkSubmit() {
		if (requiredFields.length > 0) {
			requiredFields.each(function () {
				const field = $(this)
				const input = field.find('input, textarea, select')
				const error = field.find('.form-error')
				const messageWrong = error.data('error-wrong')
				const messageEmail = error.data('error-email')
				const messagePhone = error.data('error-phone')
				const messageConfirmPassword = error.data('error-confirm-password')
				const messageFileExtension = error.data('error-file-extension')
				const messageFileSize = error.data('error-file-size')

				let value = input.val()

				if (value === '') {
					field.addClass(CLASS._invalid)
				} else {
					field.addClass(CLASS._valid)
				}

				input.on('blur keyup change', function (evt) {
					const obj = $(this)
					const type = obj.attr('type')
					const name = obj.attr('name')
					const parent = obj.parent()
					let value = obj.val()

					removeError(error, field)
					field.removeClass(CLASS._invalid).removeClass(CLASS._error).addClass(CLASS._valid)

					if (value === '') {
						showError(error, parent, messageWrong)
						isFlag = false
					} else {
						switch (type) {
							case 'email':
								if (!checkEmail(value)) {
									showError(error, parent, messageEmail)
								}
								break
							case 'number':
								if (!checkPhone(value)) {
									showError(error, parent, messagePhone)
								}
								break
							case 'radio':
								if (!input.is(':checked')) {
									showError(error, parent, messageWrong)
								}
								break
							case 'checkbox':
								if (!input.is(':checked')) {
									showError(error, parent, messageWrong)
								}
								break
							case 'password':
								const valuePassword = $('[name="password"]').val()
								const confirmPassword = $('[name="confirmation-password"]')
								const field = confirmPassword.parent()
								const valueConfirmPassword = confirmPassword.val()

								if (name === 'confirmation-password') {
									if (value !== valuePassword) {
										showError(error, field, messageConfirmPassword)
									} else {
										removeError(error, field)
									}
								}

								if (valueConfirmPassword !== '' && name === 'password') {
									const error = confirmPassword.next()
									const messageConfirmPassword = error.data('error-confirm-password')

									if (value !== valueConfirmPassword) {
										showError(error, field, messageConfirmPassword)
									} else {
										removeError(error, field)
									}
								}
								break
							case 'file':
								if (!hasExtension(value, ['.doc', '.docx', '.pdf', '.png', '.jpg', '.jpeg'])) {
									showError(error, parent, messageFileExtension)
									return false
								} else if (!validateSize(input)) {
									showError(error, parent, messageFileSize)
									return false
								}
								break
							default:
								break
						}
					}
				})

				input.on('select2:close', function (e) {
					const obj = $(this)
					const value = obj.val()
					const parent = obj.parents('.form-group')
					if (value === '') {
						field.removeClass(CLASS._invalid).addClass(CLASS._error)
						showError(error, parent, messageWrong)
					}
				})
			})

			requiredFields.each(function () {
				const field = $(this)
				const input = field.find('input, textarea, select')

				if (requiredFields.hasClass(CLASS._invalid) || requiredFields.hasClass(CLASS._error)) {
					btnSubmit.prop('disabled', true)
					btnSubmit.removeClass('btn-gradient-primary').addClass('btn-gradient-secondary')
				} else {
					btnSubmit.prop('disabled', false)
					btnSubmit.removeClass('btn-gradient-secondary').addClass('btn-gradient-primary')
				}

				input.on('blur keyup change', function (evt) {
					if (requiredFields.hasClass(CLASS._invalid) || requiredFields.hasClass(CLASS._error)) {
						btnSubmit.prop('disabled', true)
						btnSubmit.removeClass('btn-gradient-primary').addClass('btn-gradient-secondary')
					} else {
						btnSubmit.prop('disabled', false)
						btnSubmit.removeClass('btn-gradient-secondary').addClass('btn-gradient-primary')
					}
				})
			})
		}
	}

	function checkKeyCode() {
		if (requiredFields.length > 0) {
			requiredFields.each(function () {
				const field = $(this)
				const input = field.find('input, textarea, select')
				const type = input.attr('type')

				switch (type) {
					case 'number':
						input.on('keyup', function (evt) {
							return evt.keyCode !== 69
						})
						break
					default:
						break
				}
			})
		}
	}

	function showError(error, field, message) {
		error.html(message)
		action(field)
	}

	function removeError(error, field) {
		error.html('')
		field.removeClass(CLASS._error).removeClass(CLASS._focus)
	}

	function inputFocus(input, field) {
		const header = $('.header')
		const headerH = header.height()
		const inputFirst = input.first()
		const posY = el.offset().top - headerH
		if (inputFirst.attr('type') === 'hidden') {
			APP.setScrollTopAnimation(posY)
		}
		inputFirst.focus()
		field.addClass(CLASS._focus)
	}

	function action(field) {
		field.addClass(CLASS._error)
		field.removeClass(CLASS._valid)
	}

	function checkEmail(email) {
		const reg =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		return reg.test(String(email).toLowerCase())
	}

	function checkPhone(phone) {
		const reg = /(84|0[3|5|7|8|9])+([0-9]{8})\b/
		return reg.test(phone)
	}

	function objectifyForm(formArray) {
		const returnArray = {}
		for (var i = 0; i < formArray.length; i++) {
			returnArray[formArray[i]['name']] = formArray[i]['value']
		}
		return returnArray
	}

	function hasExtension(value, exts) {
		return new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$').test(value)
	}

	function validateSize(input) {
		const fileSize = input[0].files[0].size / 1024 / 1024
		if (fileSize >= 5) return false
		return true
	}

	init()
}

function recaptchaCallback() {
	const requiredFields = $('[data-required]')
	const form = requiredFields.parents('form')
	const btnSubmit = form.find('[type="submit"]')
	const gCaptcha = $('.g-recaptcha')
	const field = gCaptcha.parent()
	const error = field.find('.form-error')
	field.removeClass(CLASS._error).removeClass(CLASS._invalid).addClass(CLASS._valid)
	if (requiredFields.hasClass(CLASS._invalid) || requiredFields.hasClass(CLASS._error)) {
		btnSubmit.prop('disabled', true)
		btnSubmit.removeClass('btn-gradient-primary').addClass('btn-gradient-secondary')
	} else {
		btnSubmit.prop('disabled', false)
		btnSubmit.removeClass('btn-gradient-secondary').addClass('btn-gradient-primary')
	}
	error.html('')
}
